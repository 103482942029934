<template>
  <section>
      <section class="d-flex flex-row mb-1" v-if="!start && !viewSubmitted" >
          <v-btn 
              text 
              color="primary" 
              dense 
              small
              class="px-1" 
              @click="(prevRoute && prevRoute.name === 'User Course Details') ? $router.back() : $router.push({ name: 'User Course Details' , params: { id: $route.params.course_uuid}, query: { timestamp: Date.now() }})">
              <v-icon small>
              mdi-chevron-left
              </v-icon>
              BACK
          </v-btn>
          <v-spacer/>
          <ButtonPrimary
              :outlined="true"
              :label="'Generate Certificate'"
              :icon="'mdi-certificate-outline'"
              :loading="certificateLoading"
              :disabled="getProgress !== 100"
              class="hidden-sm-and-down mb-2"
              @click="generateCertificate"
          />
      </section>

      <LinearProgress v-if="pageLoading" />
      
      <v-row dense v-if="!start && !viewSubmitted">
          <v-col cols="12" md="4" class="order-last order-md-first">
              <v-sheet class="custom-border border pa-4 fade">
                  <div class="secondary-font f12 fw600 secondary-1--text no-spacing my-2 ">
                      LEARNING PROGRESS ( {{ progressLoading ? 0 :getProgress }} % )
                      <section class="d-flex flex-row align-center secondary-1--text mt-2">
                          <v-progress-linear 
                              height="8"
                              :value=" progressLoading ? 0 :getProgress"
                              rounded
                              color="success"
                              background-color="grey lighten-3"
                              class="mr-2"
                              :indeterminate="progressLoading"
                          ></v-progress-linear>
                      </section>
                  </div>
                  <v-sheet class="overflow-y-auto scroller" max-height="310" min-height="42vh"  id="parent">
                      <MaterialTimelineFix
                          elevation='0'
                          :course="course" 
                          id="materialTimeline"
                          @click="(e) => goTo(e)"
                          :loading="savingProgressLoading"
                          :current="current_content ? current_content.uuid : ''"/>
                  </v-sheet>
              </v-sheet>
          </v-col>
          <v-col cols="12" md="8" class="py-1">
              <v-sheet class="custom-border border fade" :min-height="['course_evaluation', 'module_evaluation', 'assessment'].includes(current_content_type) ? `54vh` : ''">
                  <StartScreenEvaluation 
                      v-if="!pageLoading && ['course_evaluation', 'module_evaluation'].includes(current_content_type)"
                      @start="start = true" 
                      :evaluation="current_content"
                      :current_content_type="current_content_type"
                      @viewSubmission="viewSubmitted = true" />
                  
                  <StartScreen 
                      v-if="!pageLoading && ['assessment'].includes(current_content_type)"
                      @setAnswers="(e) => answers = e"
                      :data="current_content"
                      @viewAccepted="viewSubmitted = true"
                      @start="() => {
                          start = true
                          $store.commit('usr/startAssessmentMutation', {
                              action: 'start',
                              status: true
                          })
                      }" />

                  <section
                      v-if="!pageLoading && ['topic', 'subtopic'].includes(current_content_type) && current_content.learning_material">
                      
                      <vimeo v-if="['mp4', 'youtube'].includes(current_content.learning_material.file_type) && showVimeo"
                          :uri="current_content.learning_material.url"
                          :type="current_content.learning_material.file_type"
                          :data="current_content"
                          @end="saveTopicSubtopicProgress()"
                          class="fade"
                      />
                      <div id="container-frame" class="container-frame fade" v-else>
                          <iframe 
                              v-if="['ppt', 'pptx', 'docx'].includes(current_content.learning_material.file_type)"
                              id="material-frame"
                              :src="`https://view.officeapps.live.com/op/embed.aspx?src=${current_content.learning_material.url}`"
                              class="responsive-iframe"
                              allowfullscreen
                              webkitallowfullscreen="true"
                              mozallowfullscreen="true"
                          />
                          <v-img 
                              v-else-if="['png', 'jpg', 'jpeg'].includes(current_content.learning_material.file_type)"
                              id="responsive-iframe"
                              class="fade"
                              :src="current_content.learning_material.url"
                          />
                          <iframe 
                              v-else
                              id="material-iframe"
                              :src="current_content.learning_material.url"
                              class="responsive-iframe fade"
                              allowfullscreen
                              webkitallowfullscreen="true"
                              mozallowfullscreen="true"
                          /> 
                      </div>
                  </section>
                  
                  <v-sheet height="50vh" class="d-flex align-center justify-center" v-else-if="!pageLoading && ['topic', 'subtopic'].includes(current_content_type) && !current_content.learning_material">
                      No learning material found
                  </v-sheet>   
              </v-sheet>

          </v-col>
      </v-row>

      <Quiz 
          v-if="start && ['assessment'].includes(current_content_type)"
          :data="current_content" 
          :answers="answers" 
          @submit="submitDialog = true" 
          @back="() => {
              leaveDialog = {status: true, action: 'leave'}
              $store.commit('usr/startAssessmentMutation', {
                  action: 'left',
                  status: false
              })
          }" 
      />

      <LatestSubmission 
          v-if="viewSubmitted && ['assessment'].includes(current_content_type)"
          :data="current_content"
          @backSubmitted="viewSubmitted = false"/>


      <DialogSuccess
          :dialog="successDialog"
          :type="current_content_type === 'assessment' ? 'assessment' : 'evaluation'"
      />

      <DialogLeave
          :dialog="leaveDialog" 
          @submit="leave"
          @cancel="leaveDialog.status=false"
      />

      <DialogSubmit 
          :dialog="submitDialog" 
          :loading="submitLoading"
          @submit="proceedSubmit"
          @cancel="submitDialog=false"
          @review="submitDialog=false"
      />

      <Evaluation 
          v-if="start && ['course_evaluation', 'module_evaluation'].includes(current_content_type)" 
          :evaluation="current_content"
          :current_content_type="current_content_type"
          @back="leaveDialog = {status: true, action: 'leave'}" 
          @submit="(e) => confirmEvaluation(e)"/>
      
      <LatestEvaluation 
          v-if="viewSubmitted && ['course_evaluation', 'module_evaluation'].includes(current_content_type)"
          :evaluation="current_content"
          :current_content_type="current_content_type"
          @back = "viewSubmitted = false"/>
      
          
      <CertificateDialog 
          :dialog="certificateDialog"
          :loading="certificateLoading"
          :url="certificateURL"
          @close="certificateDialog = false"
          />

  </section>
</template>


<style>
  .container-frame {
      position: relative;
      overflow: hidden;
      width: 100%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }

  .responsive-iframe {
      height: 54vh;
  }

  @media only screen and (max-width: 768px){
  .responsive-iframe {
      height: 54vh;
      width: 100%;
  }
}

</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Quiz from './Quiz.vue';
import StartScreen from '@/components/user/assessment/StartScreen.vue';
import Evaluation from './Evaluation.vue';
import LatestSubmission from './LatestSubmission.vue';
import MaterialTimelineFix from '@/components/user/course/MaterialTimelineFix.vue';
import DialogSubmit from '@/components/user/course/DialogSubmit.vue';
import DialogLeave from '@/components/user/course/DialogLeave.vue';
import DialogSuccess from '@/components/user/course/DialogSuccess.vue';
import StartScreenEvaluation from '@/components/user/evaluation/StartScreen.vue';
import LatestEvaluation from '@/components/user/evaluation/LatestEvaluation.vue';
import CertificateDialog from '@/components/main/CertificateDialog.vue';
import RadioButtons from '@/components/teacher/evaluation/RadioButtons.vue';
import Checkboxes from '@/components/teacher/evaluation/Checkboxes.vue';

export default {

  components: {
      StartScreen,
      Quiz,
      LatestSubmission,
      MaterialTimelineFix,
      StartScreenEvaluation,
      LatestEvaluation,
      Evaluation,
      DialogSubmit,
      DialogLeave,
      DialogSuccess,
      CertificateDialog,
      RadioButtons,
      Checkboxes,
  },

  data: () => ({
      showVimeo: false,
      review: false,
      start: false,
      answers: null,
      viewSubmitted: false,
      successDialog: false,
      submitDialog: false,
      leaveDialog: {
          status: false,
          action: ''
      },
      postType: false,
      pageLoading: false,
      submitLoading: false,
      certificateLoading: false,
      savingProgressLoading: false,
      progressLoading: false,
      current_content: null,
      current_content_type: '',
      certificateLoading: false,
      certificateDialog: false,
      certificateURL: null,
      progress: {
          total_done_topics: 0,
          total_done_sub_topics: 0,
          total_done_assessments: 0,
          assessments_count: 0,
          topics_count: 0,
          sub_topics_count: 0,
          total_done_course_evaluations: 0,
          total_done_module_evaluations: 0,
          course_evaluations_count: 0,
          module_evaluations_count: 0,
      },
      prevRoute: null,
  }),

  created(){
      document.addEventListener('fullscreenchange', this.handleFullscreenChange);

      this.getCourse()
      this.getCourseProgress()
  },

  beforeUnmount() {
      document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
  },

  computed: {
      ...mapState('usr', {
          course: (state) => state.object,
          modules: (state) => state.modules,
          content: (state) => state.content,
          startAssessment: (state) => state.startAssessment,
      }),

      getProgress(){
          let _progress = 0

          _progress = this.$userProgress(this.progress)

          if(this.course && !this.course.is_completed && _progress === 100 && !this.progressLoading && !this.pageLoading) {
              this.pageLoading = true
              this.markCourseAsCompletedAction({ course_id: this.course.id }).then(() => {
                  this.course.is_completed = true
                  this.pageLoading = false
                  this.alertMutation({
                      show: true,
                      text: 'Course Completed!',
                      type: "success"
                  })
              }).catch(e => {
                console.log(e)
                  this.pageLoading = false
                  this.alertMutation({
                      show: true,
                      text: 'Something went wrong in saving your progress!',
                      type: "error"
                  })
              })
          }

          return Number.isNaN(_progress) ? 0 :_progress
      },
  },

  methods: {
      ...mapMutations(['alertMutation']),
      ...mapMutations('usr', ['modulesMutation']),

      ...mapActions('usr', [
          'generateCertificateAction',
          'showCourseAction',
          'getModuleContentAction',
          'getCourseProgressAction',
          'saveDoneProgressAction',
          'submitCourseEvaluationAnswersAction',
          'submitModuleEvaluationAnswersAction',
          'storeAssessmentAnswersAction',
          'getAssessmentAction',
          'markCourseAsCompletedAction',
          'getCourseEvaluationsAction'
      ]),

      autoDoneProgress(){
          if(['png', 'jpg', 'jpeg', 'pdf', 'gdrive_link', 'docx', 'ppt'].includes(this.current_content.learning_material.file_type)) {
              if(!this.current_content.student_done_status) this.saveTopicSubtopicProgress()
          }
      },

      setCurrentContentType() {
          if(this.current_content.hasOwnProperty('topic_has_assessments')) {
              setTimeout(() => {
                  if(this.pageLoading) {
                      this.pageLoading = false
                  }
                  this.showVimeo = true
              }, 1000)
              this.current_content_type = 'topic'
              this.autoDoneProgress()
          }
          if(this.current_content.hasOwnProperty('sub_topic_has_assessments')) {
              setTimeout(() => {
                  if(this.pageLoading) {
                      this.pageLoading = false
                  }
                  this.showVimeo = true
              }, 1000)
              this.current_content_type = 'subtopic'

              this.autoDoneProgress()
          }
          if(this.current_content.hasOwnProperty('course_evaluation_is_locked')) this.current_content_type = 'course_evaluation'
          if(this.current_content.hasOwnProperty('module_evaluation_is_locked')) this.current_content_type = 'module_evaluation'
          if(this.current_content.hasOwnProperty('type_of_assessment')) this.current_content_type = 'assessment'
      },

      goTo(e) {
          this.showVimeo = false
          this.current_content = this.content.find(item => item.uuid === e.uuid)

          if(this.current_content.hasOwnProperty('topic_has_assessments') || this.current_content.hasOwnProperty('sub_topic_has_assessments')) {
              this.pageLoading = true
          }

          this.setCurrentContentType()

          this.$router.replace({ params: { id: this.$route.params.id, content_uuid: e.uuid}, timestamp: Date.now()})
      },

      getCourse(){
          this.pageLoading=true
          this.showCourseAction(this.$route.params.id).then(() => {
              this.pageLoading = false

              this.current_content = this.content[this.$route.params.index]

              this.setCurrentContentType()
          }).catch(() => {
              this.pageLoading = false
              this.alertMutation({
                  show: true,
                  text: 'Something went wrong',
                  type: "error"
              })
          })
      },

      getCourseProgress(){
          this.progressLoading = true
          this.getCourseProgressAction(this.$route.params.id).then(res => {
              this.progressLoading = false
              this.progress = res
          }).catch(() => {
              this.progressLoading = false
              this.progress = {
                  assessments_count: 0,
                  course_evaluations_count: 0,
                  module_evaluations_count: 0,
                  sub_topics_count: 0,
                  topics_count: 0,
                  total_done_assessments: 0,
                  total_done_course_evaluations: 0,
                  total_done_module_evaluations: 0,
                  total_done_sub_topics: 0,
                  total_done_topics: 0,
              }
              this.alertMutation({
                  show: true,
                  text: 'Something went wrong in fetching your progress',
                  type: "error"
              })
          })
      },

      generateCertificate(){
          this.certificateLoading = true
          this.generateCertificateAction({course_id: this.course.id}).then(res => {
              this.certificateDialog = true
              this.certificateLoading = false
              this.certificateURL = res
          }).catch(res => {
              this.certificateLoading = false
          })
      },

      updateStateModules(updated_module){
          let index = this.modules.findIndex(item => item.id === updated_module.id);

          if (index !== -1) {
              this.modules.splice(index, 1, updated_module);
              this.modulesMutation(this.modules)
          }
      },

      showErrorProgressSave(){
          this.alertMutation({
              show: true,
              text: 'Something went wrong in saving your progress, please reload the page',
              type: "error"
          })
      },

      updateNextContent(e) {
          let _index = this.content.findIndex(item => item.uuid === e.uuid)
          let next_content = this.content[_index + 1]

          if(next_content && next_content.module_id !== e.module_id) {
              this.getModuleContentAction(e.module_id).then(res => {
                  this.updateStateModules(res)
                  this.getModuleContentAction(next_content.module_id).then(res => {
                      this.updateStateModules(res)
                      this.savingProgressLoading = false
                  }).catch(() => {
                      this.showErrorProgressSave()
                      this.savingProgressLoading = false
                  })
              }).catch(() => {
                  this.showErrorProgressSave()
                  this.savingProgressLoading = false
              })
          } else {
              this.getModuleContentAction(e.module_id).then(res => {
                  this.updateStateModules(res)
                  this.savingProgressLoading = false
              }).catch(() => {
                  this.showErrorProgressSave()
                  this.savingProgressLoading = false
              })
          }
      },

      saveTopicSubtopicProgress(e){

          if(!this.current_content.student_done_status) {
              this.savingProgressLoading = true
              
              this.saveDoneProgressAction({
                  model_uuid: this.current_content.uuid, 
                  model_type: this.current_content_type === 'topic' ? 'Topic' : 'SubTopic',
                  course_id: this.course.id
              }).then(()=>{
                  this.updateNextContent(this.current_content)
                  this.getCourseProgress()
              }).catch(() => {
                  this.savingProgressLoading = false
                  this.showErrorProgressSave()
              })

          }
      },

      proceedSubmit() {
          if(this.current_content_type === 'assessment') {
              this.postType = 'submitted'
              
              this.$store.commit('usr/startAssessmentMutation', {
                  action: 'submitted',
                  status: false
              })
              this.submitAssessment()
          } else this.submitEvaluation()
      },

      submitAssessment() {
          let form = new FormData()

          this.submitLoading = true
          this.savingProgressLoading = true

          form.append('assessment_id', this.current_content.id)
          form.append('post_type', this.startAssessment.action)
          form.append('course_id', this.current_content.course_id)
          this.answers.forEach((el, i) => {
              form.append('question_id[]', el.question_id)
              form.append('answer[]', el.answer)
              form.append('support_answer[]', el.support_answer)
              if(el.file) {
                  form.append(`file[${i}]`, el.file) 
              } else {
                  form.append(`file[${i}]`, '') 
              }
              form.append('is_require_support_answer[]', el.is_require_support_answer)
              form.append('is_require_file_attachment[]', el.is_require_file_attachment)
          })

          this.storeAssessmentAnswersAction(form).then(() => {
              this.getAssessmentAction(this.current_content.uuid).then(res => {
                  this.current_content = res
                  this.start = false
                  this.review = false
              }).catch(() => {
                  this.start = false
                  this.review = false
              })
              this.getCourseProgress()
              this.submitLoading = false
              this.submitDialog = false
              this.getModuleContentAction(this.current_content.module_id).then(res => {
                  this.updateStateModules(res)
                  this.savingProgressLoading = false
              }).catch(() => {
                  this.savingProgressLoading = false
                  this.showErrorProgressSave()
              })
          }).catch(() => {
              this.submitLoading = false
              this.submitDialog = false
              this.savingProgressLoading = false
              this.alertMutation({
                  show: true,
                  text: 'Something went wrong',
                  type: "error"
              })
          })
      },

      leave(){
          this.leaveDialog.status=false
          this.start = false
          this.answers = []
      },

      confirmEvaluation(data) {
          this.answers = data
          this.submitDialog = true
      },

      submitEvaluation() {

          let _form = new FormData()

          this.submitLoading = true
          this.savingProgressLoading = true
          if(this.current_content_type === 'course_evaluation') {
              this.answers.forEach((item, i) => {
                  _form.append(`course_id[${i}]`, item.id)
                  _form.append(`course_evaluation_id[${i}]`, item.evaluation_id)
                  _form.append(`course_evaluation_question_id[${i}]`, item.evaluation_question_id)
                  _form.append(`answer[${i}]`, JSON.stringify(item.answer))
                  _form.append(`file_attachment[${i}]`, item.file_attachment)
              })

              this.submitCourseEvaluationAnswersAction(_form).then(res => {
                  this.savingProgressLoading = false
                  this.submitLoading = false
                  this.submitDialog = false
                  this.evalSuccessDialog = true
                  this.start = false
                  this.review = false
                  this.getCourseProgress()
                  this.getCourseEvaluationsAction(this.$route.params.id).then(res => {
                      this.course.is_completed = res.is_completed
                      this.savingProgressLoading = false
                  }).catch(() => {
                      this.savingProgressLoading = false
                      this.showErrorProgressSave()
                  })
              }).catch(() => {
                  this.alertMutation({
                      show: true,
                      text: 'Something went wrong in submitting your evaluation',
                      type: "error"
                  })
                  this.savingProgressLoading = false
                  this.submitLoading = false
                  this.submitDialog = false
              })
          } else {  
              this.answers.forEach((item, i) => {
                  _form.append(`course_id[${i}]`, this.course.id )
                  _form.append(`module_id[${i}]`, item.id)
                  _form.append(`module_evaluation_id[${i}]`, item.evaluation_id)
                  _form.append(`module_evaluation_question_id[${i}]`, item.evaluation_question_id)
                  _form.append(`answer[${i}]`, JSON.stringify(item.answer))
                  _form.append(`file_attachment[${i}]`, item.file_attachment)
              })

              this.submitModuleEvaluationAnswersAction(_form).then(res => {
                  this.savingProgressLoading = false
                  this.submitLoading = false
                  this.submitDialog = false
                  this.evalSuccessDialog = true
                  this.start = false
                  this.review = false
                  this.getCourseProgress()
                  this.getModuleContentAction(this.current_content.module_id).then(res => {
                      this.updateStateModules(res)
                      this.savingProgressLoading = false
                  }).catch(() => {
                      this.savingProgressLoading = false
                      this.showErrorProgressSave()
                  })
              }).catch(() => {
                  this.alertMutation({
                      show: true,
                      text: 'Something went wrong in submitting your evaluation',
                      type: "error"
                  })
                  this.submitLoading = false
                  this.submitDialog = false
                  this.savingProgressLoading = false
              })
          }
      },

      getChoices(data){
          let choices = {}

          data.forEach(choice => {
              choices[choice.id] = choice.title
          })

          return choices;
      },

      getAnswer(data){
          if(data){
              if( typeof(data) === 'string' || Array.isArray(data) ) {
                  if(data.length > 0 && data[0].id) {
                      return data[0].id
                  }
                  return data
              }
              return ''
          }
          return ''
      },

      getAnswerMultiple(data){
          let answers = []
          if(data.length > 0){
              data.forEach(ans => {
                  answers.push(ans.id)
              })
          }
          return answers
      },

      getCorrectAnswer(data){
          if(!data) return ''
          return data.map(answer => answer.title)        // Extract the titles
              .join(', '); 
      },

      toggleFullScreen() {
          var iframe = document.getElementById('material-iframe');
          if (iframe.requestFullscreen) {
              iframe.requestFullscreen();
          } else if (iframe.mozRequestFullScreen) { // Firefox
              iframe.mozRequestFullScreen();
          } else if (iframe.webkitRequestFullscreen) { // Chrome, Safari and Opera
              iframe.webkitRequestFullscreen();
          } else if (iframe.msRequestFullscreen) { // IE/Edge
              iframe.msRequestFullscreen();
          }
      }
  },

  watch: {
      successDialog(val) {
          if(val) {
              setTimeout(() => {
                  this.successDialog= false
              }, 3000);
          }
      },

      startAssessment(val) {
          if(!val.status && val.action == 'ended') {
              this.postType='ended'
              this.submitAssessment()
          }
      },
  },

  beforeRouteEnter(to, from, next) {

      if(!from.name) {
          location.href = `/course/${to.params.id}`
      }
      
      next(vm => {
          vm.prevRoute = from          
      })
  }
}

</script>