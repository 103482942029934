<template>
    <v-list-item dense 
        class="d-flex pt-2"
        :class="[data.valid_until !== 'anytime' ? 'align-start' : 'align-center', data.uuid === current ? 'warning-3' : '']"
        :disabled="module_is_locked"
        @click="$emit('click', data)"
    >
        <v-icon small color="primary">
            mdi-file-document-edit-outline
        </v-icon>
        <v-list-item-title class="roboto ml-2 f13 fw500" :class="module_is_locked ? '' : 'secondary-1--text'">
            <div class="f9">
                Evaluation
            </div>
            {{ data.title }}

            <div class="f10 secondary-2--text mt-1 mb-3 text-wrap" v-if="data.valid_until !== 'anytime'">
                This evaluation will be accessible on {{ $dateFormat.mmDDyy(data.valid_until) }}
            </div>
        </v-list-item-title>

        <v-spacer></v-spacer>

        <v-icon small color="primary">
            {{ type === 'module' ? (data.module_evaluation_is_locked ? 'mdi-lock-outline' : 'mdi-lock-open-outline') : (data.course_evaluation_is_locked ? 'mdi-lock-outline' : 'mdi-lock-open-outline')}}
        </v-icon>

        <v-progress-circular
            indeterminate
            width="3"
            size="25"
            v-if="loading && data.uuid === current "
            color="primary"/>
        <v-badge
            v-else
            bordered
            class="thin-border"
            :color="type === 'module' ? (data.student_module_evaluations_count > 0 ? 'success' : ( data.uuid === current ? 'warning' : '' )) : (data.student_course_evaluations_count > 0 ? 'success' : ( data.uuid === current ? 'warning' : '' ))"
            size='10'
            inline
            :icon="type === 'module' ? (data.student_module_evaluations_count > 0 ? 'mdi-check' : '') : (data.student_course_evaluations_count > 0 ? 'mdi-check' : '')"
        >
        </v-badge>
        </v-list-item>
</template>

<script>

export default {
    props: ['data', 'module_is_locked', 'type', 'current', 'loading']
}

</script>